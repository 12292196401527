
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    Card,
    Table,
    Paper,
    Badge,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TextField,
    TableHead,
    TableFooter,
    IconButton,
    Avatar,
    Tooltip,
    Stack,
} from '@mui/material';
import dayjs from "dayjs";
import { filter } from 'lodash';
import "./InsertAdvancessPopup.css";
import Iconify from '../components/iconify';
import { GetMRPChangesItems, InsertCommitments } from '../components/api/DailyAccountApiService';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { UserListHeadNNP } from '../sections/@dashboard/user';
import Label from '../components/label';
import Loader from '../components/loader/Loader';

const COMMITMENT_TABLE = [
    { id: 'PrGRNo', label: 'Grno', alignRight: false },
    { id: 'PrInvoiceNo', label: 'Inv No', alignRight: false },
    { id: 'PrInvoiceDate', label: 'Inv DT', alignRight: false },
    { id: 'PrSLDESC', label: 'Suplier Name', alignRight: false },
    
    { id: 'PrPurRemarks', label: 'Remarks', alignRight: false },
    { id: 'PrPack', label: 'Pack', alignRight: false },
    { id: 'PrITQTY', label: 'Qty', alignRight: false },
    { id: 'PrGroseStrip', label: 'Cost', alignRight: false },
    { id: 'PrDISRT', label: 'DisAmt', alignRight: false },
    { id: 'PrVATAMT', label: 'TaxAmt', alignRight: false },
    { id: 'PrNetStrip', label: 'Net/Strip', alignRight: false },
    { id: 'PrMRP', label: 'MRP', alignRight: false },
    { id: 'PrInvValEntered', label: 'Inv-Val', alignRight: false },
    { id: 'PrProfitStrip', label: 'Pro/Strip', alignRight: false },
    { id: 'PrBATCHNO', label: 'Batch', alignRight: false },
    { id: 'PrEXP_DT', label: 'Exp', alignRight: false },
    // { id: 'PrCNT', label: 'Cnt', alignRight: false },
];

function MRPChangeListPopup(props) {

    const [DailyAccount, setDailyAccount] = useState([
        {
          id: 1,
          oPCash: 0,
        },
      ]);
    const [commitOpen, setcommitOpen] = useState(0);
    const [commitClose, setcommitClose] = useState(0);
    const [commitCancel, setcommitCancel] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [loginuserDetailId, setLoginuserDetailId] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(65);
    const [show, setShow] = useState(false);
    const [userAccounts, setUserAccounts] = useState([{}]);
    const [filterName, setFilterName] = useState('');
    const filteredAccounts = applySortFilter(userAccounts, getComparator(order, orderBy), filterName);
    const handleClose = () => setShow(false);
    const [loader, setLoader] = useState(false);

    const handleShow = (id) => {
        setShow(true)
        fetchDailyAccountsDetailsData(id);
    };
    
    const handleCloseModal = (st) => {
        setShow(st)
    }

    const fetchDailyAccountsDetailsData = async (shop, itemid) => {
        try {
            console.log('res11');
            console.log(itemid);
            const res = await GetMRPChangesItems(props.ShopId, '', props.id, 1);
            if (res.data) {
                setUserAccounts(res.data);
            }
        }
        catch (error) {
            console.error('Error in fetchDailyAccountsDetailsData:', error);
        }
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }



    return (
        <>
            <IconButton className='dot' size="medium" color="inherit" onClick={() => handleShow(props.id)}>
                <Iconify icon={'eva:eye-fill'} style={{ fontSize: '24px' }} />
            </IconButton>
            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                className="custom-modal"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Item Detail List - (in Strip)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loader ? (<div className="table-loader">
                        <Loader />
                    </div>
                    ) : (
                        <form id='InsertCommitmentPopup' name='Edwit' >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead />
                                    <TableBody />
                                    <TableFooter />
                                </Table>
                            </TableContainer>
                            <Card>
                                <Scrollbar>
                                    <Paper style={{ overflow: 'auto' }}>
                                        <TableContainer >
                                            <Table stickyHeader aria-label="sticky table">
                                                <UserListHeadNNP
                                                    order={order}
                                                    orderBy={orderBy}
                                                    headLabel={COMMITMENT_TABLE}
                                                    rowCount={userAccounts.length}
                                                    numSelected={selected.length}
                                                />
                                                <TableBody>
                                                    {filteredAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                        const { PrGRNo,PrInvoiceNo, PrInvoiceDate, PrSLDESC, PrMRP, PrInvValEntered, PrPurRemarks, PrPack,
                                                            PrITQTY, PrGroseStrip, PrDISRT, PrDISAMT, PrVATAMT,PrNetStrip, PrProfitStrip, PrBATCHNO, PrEXPDT, PrCNT
                                                        } = row;

                                                        


                                                        const lastdayclosingcash = filteredAccounts[(index > 0 && (index - 1) || index)].cashbox;
                                                        return (
                                                            <TableRow hover key={PrInvoiceNo} tabIndex={-1}>
                                                                {/* <TableCell align="left">
                                                                {dayjs(date).format('DD-MMM')}
                                                            </TableCell> */}
                                                                {/* <TableCell align="right" className={index > 2 && (lastdayclosingcash === opcash && 'c' || 'cellred') || 'c'}>{opcash}</TableCell> */}
                                                                <TableCell align="right">{PrGRNo}</TableCell>
                                                                <TableCell align="left">{PrInvoiceNo}</TableCell>
                                                                <TableCell align="right">{
                                                                    dayjs(PrInvoiceDate).format('DD-MMM')}</TableCell>
                                                                <TableCell align="left">{PrSLDESC}</TableCell>
                                                                
                                                                <TableCell align="right" className='total' >{PrPurRemarks}</TableCell>
                                                                {/* <TableCell align="right" className={(Adjest < 0 && 'cellred') || (Adjest > 250 && 'cellblu') || 'cellNor'}>{ Adjest}</TableCell> */}
                                                                <TableCell align="right">{PrPack}</TableCell>
                                                                <TableCell align="right">{PrITQTY}</TableCell>
                                                                <TableCell align="right">{PrGroseStrip}</TableCell>
                                                                <TableCell align="right">
                                                                    
                                                                             
                                                                    <Tooltip title={`${PrDISRT}%`} followCursor>
                                                                        {
                                                                            <Badge  color="info" variant="dot"> </Badge>
                                                                        }
                                                                    </Tooltip>
                                                                    {`      .${PrDISAMT}`}

                                                                </TableCell>
                                                                <TableCell align="right">{PrVATAMT}</TableCell>
                                                                <TableCell align="right">{PrNetStrip}</TableCell>
                                                                <TableCell align="right">{PrMRP}</TableCell>
                                                                <TableCell align="right">{PrInvValEntered}</TableCell>
                                                                <TableCell align="right">{PrProfitStrip}</TableCell>
                                                                <TableCell align="right">{PrBATCHNO}</TableCell>
                                                                <TableCell align="right">{PrEXPDT}</TableCell>
                                                                <TableCell align="right">{PrCNT}</TableCell>
                                                                {/* <TableCell align="right" className='total'>{TotalOut}</TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    {<Tooltip title={shorten} followCursor>
                                                                        {
                                                                            <Avatar alt={name} src={avatarUrl} />
                                                                        }
                                                                    </Tooltip>}
                                                                </Stack>
                                                            </TableCell> */}

                                                                {/* <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    <Tooltip title={(status === 0 && 'Pending') || (status === 2 && 'Edited') || (status === 1 && 'Ok')} followCursor>
                                                                            <Label name="ed" color={(status === 1 && 'success') || (status === 2 && 'info') || 'error'}>{
                                                                                (status === 0 && 'P') || (status === 2 && 'Ed') || (status === 1 && 'Ok')
                                                                            }</Label>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell> */}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Scrollbar>
                            </Card>

                        </form>)}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default MRPChangeListPopup;