import { Navigate } from "react-router-dom";

export const SetupInterceptors = (apiClient) => {
    let isRefreshing = false;
    const refreshSubscribers = [];

    apiClient.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    apiClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                !originalRequest._retry &&
                !localStorage.getItem("refreshTokenInProgress")
            ) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    const refreshToken = localStorage.getItem("RefreshToken");
                    return apiClient
                        .post(
                            `/RefreshToken?refreshToken=${refreshToken}`,
                            JSON.parse(localStorage.getItem("user"))
                        )
                        .then((res) => {
                            isRefreshing = false;
                            if (res.status === 200) {
                                localStorage.setItem("token", res.data.Token.Token);
                                localStorage.setItem("isAuthenticated", true);
                                localStorage.setItem("branchId", res.data.CurrentBranchId);
                                localStorage.setItem("userBranchId", res.data.UserBranchId);
                                localStorage.setItem("branchName", res.data.BRANCHName_En);
                                localStorage.setItem("userId", res.data.UserId);
                                localStorage.setItem("userName", res.data.Name);
                                localStorage.setItem("RefreshToken", res.data.RefreshToken);
                                localStorage.setItem("user", JSON.stringify(res.data.user));
                                originalRequest.headers.Authorization = `Bearer ${res.data.Token.Token}`;
                                return apiClient(originalRequest);
                            }
                            return logout();
                        })
                        .catch(() => logout());
                }
                return new Promise((resolve) => {
                    refreshSubscribers.push(() => {
                        resolve(apiClient(originalRequest));
                    });
                });
            }
            return Promise.reject(error);
        }
    );

    function logout() {
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("branchId");
        localStorage.removeItem("userBranchId");
        localStorage.removeItem("branchName");
        localStorage.removeItem("userId");
        localStorage.removeItem("userName");
        localStorage.removeItem("RefreshToken");
        localStorage.removeItem("user");
        // <Navigate to="/login" />;
        return true;
    }
};
