// *** Edwin
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// *** Edwin Tab
import * as React from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';


import './MRPProfits.css';

// ** Date Formating
import dayjs from "dayjs";

// components

// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Tooltip,
    Checkbox,
    TableRow,
    Badge,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    CircularProgress,
    TableContainer,
    TablePagination,
    Chip,
    Button,
    Select,
    MenuItem
} from '@mui/material';

import { filter } from 'lodash';

// Popus
import { v4 as uuidv4 } from 'uuid';
import InsertAdvancess from './InsertAdvancess'
import InsertAccounts from './InsertAccounts'
import InsertCrIn from './InsertCrIn'
import EditAccounts from './EditAccounts'
import InsertPurch from './InsertPurch'
import InsertAdvUsed from './InsertAdvUsed'
import InsertCrOut from './InsertCrOut'
import FileProgressBar from './FileProgressBar'


// components
import Iconify from '../components/iconify';
import Label from '../components/label';
// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../sections/@dashboard/app';
import { GetUserDetails, GetMRPChangesItems, UpdateNonBill } from '../components/api/DailyAccountApiService';
import Loader from '../components/loader/Loader';
import CustomizedDatePopup from '../Popups/CustomizedDatePopup';


import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadNNP, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { variables } from '../Variables';

import "./NonBillView.css";
import MRPChangeListPopup from '../Popups/MRPChangeListPopup';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',

    boxShadow: 24,
    p: 4,
};





// ----------------------------------------------------------------------

const MRPChangedItemsList = [
    { id: 'PrItemID', label: 'Item ID', alignRight: false },
    { id: 'PrITType', label: 'Type', alignRight: false },
    { id: 'PrItemName', label: 'Item Name', alignRight: false },
    { id: 'PrLoc', label: 'Loc', alignRight: false },
    { id: 'PrCNT', label: 'Count', alignRight: false },
];

const ACCOINT_TABLE = [
    { id: 'grno', label: 'Grno', alignRight: false },
    { id: 'invno', label: 'Inv No', alignRight: false },
    { id: 'invdt', label: 'Inv DT', alignRight: false },
    { id: 'sname', label: 'Suplier Name', alignRight: false },
    { id: 'itemname', label: 'Item Name', alignRight: false }, 
    { id: 'pack', label: 'Pack', alignRight: false },
    { id: 'mrp', label: 'MRP', alignRight: false },
    { id: 'loc', label: 'Loc', alignRight: false },
    { id: 'prqty', label: 'Qty', alignRight: false },
    { id: 'mrf', label: 'Mfr', alignRight: false },
    { id: 'prgrostr', label: 'Gross_Strip', alignRight: false },
    { id: 'prdis', label: 'Dis %', alignRight: false },
    { id: 'prsisamt', label: 'Dis Amt', alignRight: false },
    { id: 'prnetstr', label: 'Net Strip', alignRight: false },
    { id: 'batch', label: 'Batch', alignRight: false },
    { id: 'exp', label: 'Expiry', alignRight: false }, 
    { id: 'prremarks', label: 'Remarks', alignRight: false },
    { id: 'invval', label: 'Inv Value', alignRight: false },
    

   
   
    
    
    
    
     
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.PrItemName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}



function applySortFilter2(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function addClick() {
    console.log('ssss');
}

const dropdownOptions = [
    { value: 'KO', label: 'KO' },
    { value: 'MD', label: 'MD' },
];

export default function DashboardAppPage() {
    const current = new Date();
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const shopIdFromLocalStorage = localStorage.getItem("shopId");
    const [shopID, setshopID] = useState(Number(shopIdFromLocalStorage) === 1 ? 'KO' : shopIdFromLocalStorage);

    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const theme = useTheme();


    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('date');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(65);
    const [loader, setLoader] = useState(false);


    const [selmonths, setselmonths] = useState(1);
    const [lastActiveIn, setlastActiveIn] = useState();
    const [loginuserDetailId, setloginuserDetailId] = useState();
    const [userPunchStatus, setuserPunchStatus] = useState();
    const [userPunchTxt, setuserPunchTxt] = useState();
    const [userName, setuserName] = useState();
    const [userImage, setuserImage] = useState();






    // *** Edwin
    const [account, setUserAccount] = useState([{}]);
    const [accountStatus, setAccountStatus] = useState([{}]);

    const [Userrr, setUserLis] = useState([{}]);


    const fetchUserData = async (logid) => {
        try {
            setLoader(true);
            const res = await GetUserDetails(logid, 0);
            if (res.data) {
                setUserLis(res.data);
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchUserData:', error);
        }

    };

    const chkUserLogin = () => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        if (b === null) {
            navigate('/login', { replace: true });
        }
        else {
            setuserName(JSON.parse(localStorage.getItem('mYLog')).displayName)
            setuserImage(JSON.parse(localStorage.getItem('mYLog')).ProfileImage)
            setloginuserDetailId(localStorage.getItem('userId'))
            setlastActiveIn(JSON.parse(localStorage.getItem('mYLog')).lastActiveIn)
            // { dayjs(date).format('DD-MMM') }
            // console.log(dayjs(lastActiveIn).format('DD'));
            fetchUserData(JSON.parse(localStorage.getItem('mYLog')).UId)
        }

    };

    const fetchItemView = async (shop, itemdesc, itemid) => {
        // setselmonths(cnt);
        try {
            
            console.log('fetchNonBillView');
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await GetMRPChangesItems(shopId, itemdesc, itemid, 2);
            
            
            if (res.data) {
                setUserAccount(res.data);
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchNonBillView:', error);
        }

    };


    useEffect(() => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        if (b !== null) {
            updateDashboard();
        }
        else {
            navigate('/login', { replace: true });
        }
    }, []);
    // *** End Edwin



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = ACCOINT_TABLE.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // const handleClick = (event, name) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected = [];
    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    //     }
    //     setSelected(newSelected);
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };



    // setopeningCash(account[0].opcash);

    // console.log(ss);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - account.length) : 0;

    const filteredUsers = applySortFilter(account, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const saleIncrease1 = Math.round(((accountStatus[0].monthlyAVGSales - accountStatus[0].last_MonthlyAVGSales) / accountStatus[0].last_MonthlyAVGSales) * 100);
    const saleIncrease = `(${saleIncrease1}%) than last year`
    const monTotSale = accountStatus[0].monthlyTotalSales
    const monTotPur = accountStatus[0].purchase
    const monTotPay = accountStatus[0].payments


    const [DailyAccount, setDailyAccount] = useState([
        {
            id: 1,
            oPCash: 0,
        },
    ]);


    function updateDashboard() {

        console.log('MRP Change List');
        chkUserLogin();
        fetchItemView();



        console.log('Userrr[0]')
        console.log(loginuserDetailId)
    }


    async function NonBillUpdate(crusid, crno) {
        if (crno === '' || crno === 0) {
            alert('Please enter Cr list number');
        }
        else {
            try {
                setLoader(true);
                const res = await UpdateNonBill(crno, crusid);
                if (res.data) {
                    if (res.data.Id > 0) {
                        alert(res.data.StatusMessage);
                        updateDashboard();
                    }
                }
                setLoader(false);
            } catch (error) {
                console.error('Error in UpdateNonBill:', error);
            }
            
        }
    };

    const [selectedValue, setSelectedValue] = useState('KO');

    const handleDropdownChange = (event) => {
        setshopID(event.target.value);
        setSelectedValue(event.target.value);
        fetchItemView(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title> MRP & Profit Changed Item's list| {variables.COMPANY_NAME} </title>
            </Helmet>
            <div>

                <Container maxWidth="xl">
                    {loginuserDetailId <= 3 ? (
                        <Stack direction="row" alignItems="center" mb={2}>
                            <Typography variant="p" gutterBottom mr={2}>
                                Filter By :
                            </Typography>

                            <Select width={10} value={selectedValue} onChange={handleDropdownChange}>
                                {dropdownOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    ) : ('')
                    }
                    <Grid container spacing={1} padding={0}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom mr={5}>
                                        MRP Changed Item's list
                                    </Typography>
                                </Stack>

                                <Card>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />


                                    <Scrollbar>
                                        <Paper style={{ overflow: 'auto' }}>
                                            <TableContainer >

                                                <Table stickyHeader aria-label="sticky table">
                                                    <UserListHeadNNP
                                                        order={order}
                                                        orderBy={orderBy}
                                                        // headLabel={TABLE_HEAD}
                                                        headLabel={MRPChangedItemsList}
                                                        rowCount={account.length}
                                                        numSelected={selected.length}
                                                        onRequestSort={handleRequestSort}
                                                        onSelectAllClick={handleSelectAllClick}
                                                    />
                                                    {loader ? (<div className="table-loader">
                                                        <Loader />
                                                    </div>
                                                    ) : (
                                                        <TableBody>

                                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                                const { PrItemID, PrITType, PrItemName, PrLoc,PrCNT } = row;

                                                                // console.log('Table');
                                                              
                                                                return (

                                                                    <TableRow hover key={PrItemID} tabIndex={-1} >
                                                                        <TableCell >
                                                                            <Tooltip title="View Accounts">
                                                                                <MRPChangeListPopup id={PrItemID} ShopId={shopID} />
                                                                            </Tooltip>
                                                                            {PrItemID}
                                                                        </TableCell>
                                                                        <TableCell >{PrITType}</TableCell>
                                                                        <TableCell >{PrItemName}</TableCell>
                                                                        <TableCell >{PrLoc}</TableCell>
                                                                        <TableCell  align="right">{PrCNT}</TableCell>
                                                                    </TableRow>

                                                                );
                                                            })}

                                                            {emptyRows > 0 && (
                                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                                    <TableCell colSpan={6} />
                                                                </TableRow>
                                                            )}

                                                        </TableBody>
                                                    )}
                                                    {isNotFound && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                                    <Paper
                                                                        sx={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6" paragraph>
                                                                            Not found
                                                                        </Typography>

                                                                        <Typography variant="body2">
                                                                            No results found for &nbsp;
                                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                                            <br /> Try checking for typos or using complete words.
                                                                        </Typography>
                                                                    </Paper>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>

                                            </TableContainer>
                                        </Paper>
                                    </Scrollbar>

                                    <TablePagination
                                        rowsPerPageOptions={[10, 30, 65]}
                                        component="div"
                                        count={account.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />

                                </Card>

                            </Container>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom mr={5}>
                                        Profit Changed Item's list
                                    </Typography>
                                </Stack>

                                <Card>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />


                                    <Scrollbar>
                                        <Paper style={{ overflow: 'auto', width: '100%' }}>
                                            <TableContainer sx={{ minWidth: 800 }}>

                                                <Table stickyHeader aria-label="sticky table">
                                                    <UserListHeadNNP
                                                        order={order}
                                                        orderBy={orderBy}
                                                        // headLabel={TABLE_HEAD}
                                                        headLabel={ACCOINT_TABLE}
                                                        rowCount={account.length}
                                                        numSelected={selected.length}
                                                        onRequestSort={handleRequestSort}
                                                        onSelectAllClick={handleSelectAllClick}
                                                    />
                                                    {loader ? (<div className="table-loader">
                                                        <Loader />
                                                    </div>
                                                    ) : (
                                                        <TableBody>

                                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                                const { tranid, date, nonbill, nonbillstat, advance, crin, cashin, totalIn, adjest
                                                                    , pur, card, gpay, oldbill, oldbillstat, advused, exp, crout, take, cashbox, totalOut, name, status
                                                                    , avatarUrl, crintxt, advancetxt, purchasetxt, advanceusedtxt, creditouttxt, userDetailId
                                                                    , dailyAdvTrans, dailyCrInTrans, dailyPurchTrans, dailyCrOutTrans, dailyAdvUsTrans } = row;

                                                                const shorten = name ? name.substring(0, 3) : '';

                                                                const selectedUser = selected.indexOf(name) !== -1;
                                                                // console.log('Table');
                                                                // console.log(Userrr[0].userPunchiN);
                                                                // console.log(index);
                                                                // console.log(dailyCrInTrans);
                                                                return (

                                                                    <TableRow hover key={tranid} tabIndex={-1} >
                                                                        <TableCell align="right">{tranid}</TableCell>
                                                                        <TableCell align="left">
                                                                            {dayjs(date).format('DD-MMM')}
                                                                        </TableCell>
                                                                        <TableCell align="right">{(nonbill > 0 && nonbill || '')}</TableCell>
                                                                        <TableCell align="center">
                                                                            {(nonbill > 0 &&
                                                                                (nonbillstat === 1 && <Label className='succ'> Ok </Label> || <Button size='small' className={(nonbillstat === 0 && 'pend') || (nonbillstat === 1 && 'succ') || 'pend'}
                                                                                    onClick={() => { NonBillUpdate(1, tranid) }}>
                                                                                    Pending
                                                                                </Button>)

                                                                                || '')}
                                                                        </TableCell>
                                                                        <TableCell align="right">{(oldbill > 0 && oldbill || '')}</TableCell>
                                                                        <TableCell align="center">
                                                                            {(oldbill > 0 &&
                                                                                (oldbillstat === 1 && <Label className='succ'> Ok </Label> ||
                                                                                    <Button className={(oldbillstat === 0 && 'pend') || (oldbillstat === 1 && 'succ') || 'pend'}
                                                                                        onClick={() => { NonBillUpdate(2, tranid) }}>
                                                                                        Pending
                                                                                    </Button>
                                                                                )

                                                                                || '')}
                                                                        </TableCell>

                                                                        <TableCell align="center">

                                                                            {<Tooltip title={shorten} followCursor>
                                                                                {
                                                                                    <Avatar alt={name} src={avatarUrl} />
                                                                                }
                                                                            </Tooltip>}

                                                                        </TableCell>




                                                                    </TableRow>

                                                                );
                                                            })}

                                                            {emptyRows > 0 && (
                                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                                    <TableCell colSpan={6} />
                                                                </TableRow>
                                                            )}

                                                        </TableBody>
                                                    )}
                                                    {isNotFound && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                                    <Paper
                                                                        sx={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6" paragraph>
                                                                            Not found
                                                                        </Typography>

                                                                        <Typography variant="body2">
                                                                            No results found for &nbsp;
                                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                                            <br /> Try checking for typos or using complete words.
                                                                        </Typography>
                                                                    </Paper>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>

                                            </TableContainer>
                                        </Paper>
                                    </Scrollbar>

                                    <TablePagination
                                        rowsPerPageOptions={[10, 30, 65]}
                                        component="div"
                                        count={account.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />

                                </Card>

                            </Container>
                        </Grid>
                        

                    </Grid>

                </Container>



            </div>
        </>
    );
}
