import apiClient from "./ApiClient";

export const GetBranches1 = async (code = "", name = "") => apiClient.get(`/GetBranches1?code=${code}&name=${name}`);

export const GetUserDetails = (UserDetailId, PunchType) => apiClient.post('/GetUserDetails', { UserDetailId, PunchType });

export const GetDailyAccounts = (shopid) => apiClient.get(`/GetDailyAccounts?shopid=${shopid}`);

export const GetStatus = (shopid) => apiClient.get(`/GetStatus?shopid=${shopid}`);

export const InsertDailyAccount = (data) => apiClient.post('/InsertDailyAccount', data);

export const UpdateDailyAccount = (data) => apiClient.put('/UpdateDailyAccount', data);

export const UpdateDailyAccountStatus = (data) => apiClient.put('/UpdateAccountStatus', data);

export const InsertCommitments = (data) => apiClient.post('/InsertCommitments', data);

export const InsertDailyAdvance = (data) => apiClient.post('/InsertDailyAdvance', data);

export const InsertDailyCrIn = (data) => apiClient.post('/InsertDailyCrIn', data);

export const InsertDailyPurch = (data) => apiClient.post('/InsertDailyPurch', data);

export const InsertDailyAdvUs = (data) => apiClient.post('/InsertDailyAdvUs', data);

export const InsertDailyCrOut = (data) => apiClient.post('/InsertDailyCrOut', data);

export const InsertPunchData = (data) => apiClient.post('/InsertPunchData', data);

export const GetAdvanceDetails = (status,ShopId) => apiClient.get(`/GetAdvanceDetails?status=${status}&ShopId=${ShopId}`);

export const GetAdvanceUsedDetails = (status,ShopId) => apiClient.get(`/GetAdvanceUsedDetails?status=${status}&ShopId=${ShopId}`);

export const UpdateAdvanceUsed = (advTranId, advusTranId) => apiClient.put(`/UpdateAdvanceUsed?advTranId=${advTranId}&advusTranId=${advusTranId}`);

export const GetCrDetails = (status, ShopId) => apiClient.get(`/GetCrDetails?status=${status}&ShopId=${ShopId}`);

export const GetCrOutDetails = (status, ShopId) => apiClient.get(`/GetCrOutDetails?status=${status}&ShopId=${ShopId}`);

export const GetnonpayCrOutDetails = (status, ShopId) => apiClient.get(`/GetnonpayCrOutDetails?status=${status}&ShopId=${ShopId}`);

export const UpdateCrOut = (crInTranId, crOutTranId) => apiClient.put(`/UpdateCrOut?crInTranId=${crInTranId}&crOutTranId=${crOutTranId}`);

export const Get100Days = (ShopId, fromDate = '', toDate = '') => apiClient.get(`/Get100Days?shopid=${ShopId}&fromDate=${fromDate}&toDate=${toDate}`);

export const NonBillView = (shopId, fromDate = '', toDate = '') => apiClient.get(`/NonBillView?shopId=${shopId}&fromDate=${fromDate}&toDate=${toDate}`);

export const GetMRPChangesItems = (shopId, itemdesc = '', itemid = '', showtype = 1) => apiClient.get(`/GetMRPChangedItems?shopId=${shopId}&itemdesc=${itemdesc}&itemid=${itemid}&showtype=${showtype}`);

export const PendingMonthlyCustomers = (shopId, fromDate = '', toDate = '') => apiClient.get(`/GetPendingMonthlyCustomers?shopId=${shopId}&fromDate=${fromDate}&toDate=${toDate}`);

export const Get100DaysStatus = (shopid, fromDate = '', toDate = '') => apiClient.get(`/Get100DaysStatus?shopid=${shopid}&fromDate=${fromDate}&toDate=${toDate}`);

export const UpdateNonBill = (TranId, set) => apiClient.put(`/UpdateNonBill?TranId=${TranId}&set=${set}`);

export const GetDailyCustomerCommitments = (shopid, tranId='') => apiClient.get(`/GetDailyCustomerCommitments?shopid=${shopid}&tranId=${tranId}`);

export const GetDailyAccountDetails = (tranId='',shopid='') => apiClient.get(`/GetDailyAccountDetails?tranId=${tranId}&shopid=${shopid}`);
